@import '../node_modules/animate.css/animate.min.css';

@font-face{
	font-family: 'Quicksand';
	font-style: normal;
	font-weight: normal;
	src: local('Quicksand'), url('./assets/fonts/Quicksand.ttf') format('woff');
	font-display: swap;
}

@font-face{
	font-family: 'SanstuyBrush';
	font-style: normal;
	font-weight: normal;
	src: local('SanstuyBrush'), url('./assets/fonts/SanstuyBrush.ttf') format('woff');
	font-display: swap;
}

@font-face{
  font-family: 'Montserrat';
  src: local('Montserrat'), url('./assets/fonts/Montserrat-Regular.ttf') format('woff');
  font-display: swap;
}

html,body,em,strong,span,li{
	font-family: 'Quicksand';
	font-size: 18px;
	line-height: 1.5;
	margin: 0;
    scroll-behavior: smooth;
}

:focus{ outline: none; }

.container{
	max-width: 90%;
	width: 100%;
	margin: 0 auto;
	padding: 5px 0px;
}

p{
	font-size: 22px;
	line-height: 1.5;
    font-weight: 600;
    text-align: justify;
}

.button{
    padding: 10px 40px;
    display: inline-block;
    background-color: #f9c420;
    border: 0;
    cursor: pointer;
    font-size: 20px;
    border-radius: 30px;
    text-decoration: none;
    color: #000;
    font-weight: bold;
    transition: all .6s ease-out;
    margin-top: 20px;
}

.button:hover{
    background: #004BA0;
    color: #fff;
}

h3{
    font-size: 50px;
    word-spacing: 5px;
    color: #004BA0;
    text-align: center;
    padding-bottom: 0px;
		font-family: 'Montserrat';
    font-weight: 900;
}

@media only screen and (max-width: 980px){

p{
   font-size: 20px;
   margin-block-start: 0;
}

h3{ 
	font-size: 32px;
    margin-block-end: 30px;
    margin-block-start: 30px;
}

.button{
    margin: 0 auto;
    display: block;
    text-align: center;
    max-width: fit-content;
    width: 100%;
}

}